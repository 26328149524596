<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import type { DropdownAlign } from '~/types/ui';

const breakpoints = useBreakpoints(breakpointsTailwind);
const mdAndSmaller = breakpoints.smallerOrEqual('md');

const props = withDefaults(
  defineProps<{
    align?: DropdownAlign;
    width?: string;
    offset?: number;
    type?: number;
    contentStyle?: string | object[] | object | Array<string>;
    mode?: 'click' | 'hover';
    showInMobile?: boolean;
    withSearch?: boolean;
    disabled?: boolean;
  }>(),
  {
    align: 'auto',
    width: '200px',
    offset: 8,
    type: 1,
    contentStyle: '',
    mode: 'click',
    showInMobile: true,
    withSearch: false,
    disabled: false,
  }
);

const model = defineModel<boolean>();
const emit = defineEmits(['open', 'close']);
const toggleShowDropdown = useToggle(model);

watch(model, (val) => {
  if (val) {
    emit('open');
  } else {
    emit('close');
  }
});

const openDropdown = () => {
  model.value = true;
};
const closeDropdown = () => {
  model.value = false;
};

defineExpose({
  openDropdown,
  closeDropdown,
});
</script>

<template>
  <div class="relative">
    <div v-if="mdAndSmaller" class="w-full" @click="toggleShowDropdown()">
      <slot />
    </div>

    <HDefaultDropdown
      v-if="!mdAndSmaller"
      v-model="model"
      :mode="props.mode"
      :align="props.align"
      :width="props.width"
      :offset="props.offset"
      :type="props.type"
      :content-style="props.contentStyle"
      :show-in-mobile="props.showInMobile"
      :with-search="props.withSearch"
      :disabled="props.disabled"
    >
      <div class="w-full">
        <slot />
      </div>

      <template #content="slotProps">
        <slot name="content" v-bind="slotProps" />
      </template>
    </HDefaultDropdown>

    <UModal
      v-else
      v-model="model"
      :ui="{
        container: 'flex flex-col items-center justify-end',
        base: `px-3 border border-ht-border-2 flex-col justify-start items-start gap-6 ${props.type === 2 ? 'overflow-hidden pt-3 px-0 pb-0' : ''}`,
        width: 'w-[90vw]',
        height: 'max-h-[80vh]',
        padding: 'py-4',
        overlay: {
          background: 'bg-ht-overlay-bg-fill-2 dark:bg-ht-overlay-bg-fill-2',
        },
        margin: 'md:my-4 sm:my-4',
        background: 'bg-ht-primary-bg-fill dark:bg-ht-primary-bg-fill',
        rounded: 'rounded-2xl',
        shadow:
          'drop-shadow-hub-dropdown-light dark:drop-shadow-hub-dropdown-dark',
      }"
    >
      <div
        class="max-h-[80vh] min-h-[22vh] w-full mt-2.5 flex-col justify-start items-start gap-6"
      >
        <div class="w-full h-6 flex items-start justify-center">
          <div class="rounded-3xl w-[12vw] h-1 bg-ht-btn-gray" />
        </div>
        <slot name="content" v-bind="{ close: closeDropdown }" />
      </div>
    </UModal>
  </div>
</template>
